import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Modal, Table } from "react-bootstrap";

import {
    All_Ludo_Game_History_List_POST,
    Get_Ludo_Game_History_Log_GET, Player_Ludo_Game_Session_List_POST
} from "api/ApiConst";
import { callGetApi } from "../../api/ApiCaller";

import PaginationDataTable from "./PaginationDataTable";

import dateUtilities from "utils/DateUtilities";

const LudoHistory = ({ show, hideModal }) => {
    const [showGameLogModal, setShowGameLogModal] = useState(false);
    const [sessionDetails, setSessionDetails] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [historyDatePage, setHistoryDatePage] = useState("SessionHistory");

    useEffect(() => {
        if (!showGameLogModal) {
            setSessionDetails(null);
        }
    }, [showGameLogModal]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [{
            id: "sessionId",
            name: "Session ID",
            selector: (row) => actionButtons(row),
            sortable: false,
            width: "200px"
        }, {
            id: "totalPlayerCount",
            name: "Players",
            selector: (row) => row.totalPlayerCount,
            sortable: false,
            center: true,
            // width: "100px"
        }, {
            id: "totalBetAmount",
            name: "Bet ",
            selector: (row) => row.totalBetAmount?.toFixed(2),
            sortable: false,
            center: true,
            // width: "100px"
        }, {
            id: "totalWinAmount",
            name: "Win ",
            selector: (row) => row.totalWinAmount?.toFixed(2),
            sortable: false,
            center: true,
            // width: "100px"
        }, {
            id: "startDateTime",
            name: "Started At",
            selector: (row) => dateUtilities.formattedDate(row.startDateTimeStamp, "dd-MM-yyyy p"),
            sortable: false,
            // width: "180px"
        }, {
            id: "endDateTime",
            name: "Ended At",
            selector: (row) => dateUtilities.formattedDate(row.endDateTimeStamp, "dd-MM-yyyy p"),
            sortable: false,
            // width: "180px"
        }, {
            id: "gameSessionWinnerPlayerList",
            name: "Winner (s)",
            selector: (row) => getWinnersList(row),
            sortable: false,
            // right: true,
            // width: "150px"
        }],
        [handleRefresh]
    );

    //Action Buttons
    const actionButtons = useCallback((row) => {
        return (<div
            role="button"
            className="text-primary "
            onClick={() => handleViewDetails(row)}>
            {row.sessionId}
        </div>);
    }, []);

    // Get Winners list
    const getWinnersList = (row) => {
        return (<div className="d-flex" >
            {row?.gameSessionWinnerPlayerList?.map((winner, i) =>
                getPlayerHistoryLink(winner))}
        </div>)
    }

    const handleViewDetails = (row) => {
        let url = Get_Ludo_Game_History_Log_GET.replaceAll("{recordID}", row.sessionId);
        callGetApi(url, {}, (response) => {
            console.log("response.responsePacket", response.responsePacket);
            setSessionDetails(response.responsePacket);
            setShowGameLogModal(true);
        },
            (error) => { console.log(error); }
        );
    };

    const getPlayerHistoryLink = (player) => {
        return (player.playerVisibleName !== null && player.playerVisibleName !== undefined) ?
            <div role="button" className="text-primary " key={player.playerId}
                onClick={() => setHistoryDatePage(player.playerId)}>{player.userName}</div> : "";
    }

    const playerStatus = (player) => {
        if (player.playerStatus === "KnockOut") {
            if (player.autoPlayCount === 3) {
                return `${player.playerStatus}(Timed Out)`;
            } else {
                return `${player.playerStatus}(Leaved Table)`;
            }
        } else {
            return player.playerStatus;
        }
    }
    const handlePlayerDetails = () => {
        setShowGameLogModal(false);
        handleRefresh();
    }
    const gameLogModal = () => {
        return (
            <Modal className="tablerowmodal" show={showGameLogModal} onHide={() => setShowGameLogModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter">
                <Modal.Body>
                    <div className="border-bottom d-flex justify-content-between align-items-center pb-3">
                        <h4 className="m-0">Session Details</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowGameLogModal(false)}></button>
                    </div>
                    <div className="card-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>Session ID</th>
                                    <td>{sessionDetails.sessionId}</td>

                                    <th>Total Players</th>
                                    <td>{sessionDetails.totalPlayerCount}</td>
                                </tr>

                                <tr>
                                    <th>Started At</th>
                                    <td>{dateUtilities.formattedDate(sessionDetails.startDateTimeStamp, "dd-mm-yyyy hh:mm aa")}</td>

                                    <th>Ended At</th>
                                    <td>{dateUtilities.formattedDate(sessionDetails.endDateTimeStamp, "dd-mm-yyyy hh:mm aa")}</td>
                                </tr>

                                <tr>
                                    <td colSpan={4}>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <b>Total Bet </b>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {sessionDetails.totalBetAmount.toFixed(2)}
                                            </div>
                                            <div>
                                                <b>Total Win </b>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {sessionDetails.totalWinAmount.toFixed(2)}
                                            </div>
                                            <div>
                                                <b>Total Fee </b>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {sessionDetails.totalFeeAmount.toFixed(2)}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Table bordered striped>
                            <thead>
                                <tr>
                                    <th>Player</th>
                                    <th>Bet</th>
                                    <th>Win/Loose</th>
                                    <th>Position</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sessionDetails?.gameSessionPlayerList?.map((player, i) => (
                                    <tr key={i}>
                                        <td onClick={handlePlayerDetails}>
                                            {
                                                getPlayerHistoryLink(player)
                                            }
                                        </td>
                                        <td>{player.totalBetAmount.toFixed(2)}</td>
                                        <td>{player.totalWinAmount.toFixed(2)}</td>
                                        <td>{player.winningPosition}</td>
                                        <td>{playerStatus(player)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <div className="gameSessionLogs">
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Player</th>
                                        <th>Action</th>
                                        <th>Dice Number</th>
                                        <th>Token Index</th>
                                        <th>Start Position</th>
                                        <th>End Position</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sessionDetails?.gameSessionTurnLogList?.map((player, i) => (
                                        <tr key={i}>
                                            <td>
                                                {player.playerVisibleName}
                                            </td>
                                            <td>{player.action}</td>
                                            <td>{player.diceNumber}</td>
                                            <td>{player.tokenIndex}</td>
                                            <td>{player.startPosition}</td>
                                            <td>{player.endPosition}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const handleGetHistory = () => {
        setHistoryDatePage("SessionHistory");
    }

    return (

        <Modal show={show} onHide={() => hideModal()} size="xl" backdrop="static" fullscreen={true}>
            <Modal.Body style={{ background: "#f4f5f8" }}>

                <div className='page-content p-0'>
                    <div className='container-fluid '>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                                    <h4 className='mb-sm-0 font-size-18 py-3'>Session History</h4>

                                    <button type="button" class="btn-close" aria-label="Close" onClick={hideModal}></button>
                                </div>
                            </div>
                        </div>
                        <div className='card'>

                            <div className='card-body'>
                                <>
                                    {historyDatePage !== "SessionHistory" && historyDatePage !== undefined ?
                                        <button className="bg-primary px-3 py-2 rounded-1 border-primary  text-white history-custom-btn"
                                            onClick={handleGetHistory} >All History
                                        </button> : ""
                                    }
                                    <PaginationDataTable
                                        paginationUrl={
                                            historyDatePage !== "SessionHistory" && historyDatePage !== undefined ?
                                                Player_Ludo_Game_Session_List_POST.replaceAll("{playerId}", historyDatePage) :
                                                All_Ludo_Game_History_List_POST
                                        }
                                        serverSidePagination={true}
                                        search={true}
                                        // pagination={true}
                                        columns={columns}
                                    />
                                    {showGameLogModal && gameLogModal()}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default LudoHistory;
