// export const HTTPS = "http";
// export const HOST = "192.168.1.254";
// export const PORT = "8040";
// export const CLIENT_ID = "PlanetLudo";
// export const SERVICE = "";
export const HTTPS = "##HTTPS##";
export const HOST = "##HOST##";
export const PORT = "##PORT##";
export const CLIENT_ID = "##CID##";
export const SERVICE = "";

export const BASE_URL = `${HTTPS}://${HOST}:${PORT}` + (SERVICE !== "" ? "/" + SERVICE : "");
export const BASE_URL_V1 = BASE_URL + "/api/v1/";
export const BASE_URL_Ludo_V1 = BASE_URL + "/api/v1/ludo/" + CLIENT_ID + "/";

const recordID = "{recordID}";
const activateURL = "activate/" + recordID;
const inActivateURL = "inactivate/" + recordID;
const publish = "publish/" + recordID;
const unpublish = "unPublish/" + recordID;
const deleteURL = "delete/" + recordID;
const reviveURL = "revive/" + recordID;
const deletedListURL = "deleted-list";
const listURL = "list";
const updateUrl = "update/";
const login = "login/";

// Login
export const User_Login_Post = BASE_URL_Ludo_V1 + login;

// Ludo template
const ludo_Game_Template_Url = BASE_URL_Ludo_V1 + "template/";
export const Get_Categories = ludo_Game_Template_Url + "getCategories";
export const Update_Ludo_Game_Template_Put = ludo_Game_Template_Url + updateUrl + recordID;
export const Revive_Ludo_Game_Template_Put = ludo_Game_Template_Url + reviveURL;
export const Start_Ludo_Game_Template_Put = ludo_Game_Template_Url + "startTable/" + recordID;
export const DeActivate_Ludo_Game_Template_Put = ludo_Game_Template_Url + inActivateURL;
export const Activate_Ludo_Game_Template_Put = ludo_Game_Template_Url + activateURL;
export const UnPublish_Ludo_Game_Template_Put = ludo_Game_Template_Url + unpublish;
export const Publish_Ludo_Game_Template_Put = ludo_Game_Template_Url + publish;
export const Save_Ludo_Game_Template_Post = ludo_Game_Template_Url + "save";
export const All_Ludo_Game_Template_List_Post = ludo_Game_Template_Url + listURL;
export const Deleted_Ludo_Game_Template_List_POST = ludo_Game_Template_Url + deletedListURL;
export const Details_Ludo_Game_Template_List_Get = ludo_Game_Template_Url + "get/" + recordID;
export const Delete_Ludo_Game_Template_Delete = ludo_Game_Template_Url + deleteURL;
export const Get_Ludo_Template_Waiting_List = ludo_Game_Template_Url + "getTemplateWaitingList";
export const Get_Kick_Player_From_Waiting = ludo_Game_Template_Url + "kickPlayerFromWaiting/{templateId}/{playerId}";
export const Get_Ludo_Categories_List_Get = ludo_Game_Template_Url + "getCategories";


// Ludo table controller
const ludo_Game_Table_Url = BASE_URL_Ludo_V1 + "table/";
export const All_Ludo_Game_Table_List_Post = ludo_Game_Table_Url + listURL;
export const Stop_Ludo_Game_Table_Put = ludo_Game_Table_Url + "stopTable/" + recordID;

// Game Session History
export const All_Ludo_Game_History_List_POST = BASE_URL_Ludo_V1 + "history/list";
export const Get_Ludo_Game_History_Log_GET = BASE_URL_Ludo_V1 + "history/" + recordID;
export const Player_Ludo_Game_Session_List_POST = BASE_URL_Ludo_V1 + "history/list/{playerId}";

