import { callGetApi } from 'api/ApiCaller'
import { Get_Kick_Player_From_Waiting, Get_Ludo_Template_Waiting_List } from 'api/ApiConst'
import { Modal, Table } from "react-bootstrap";
import React, { useCallback, useEffect, useState } from 'react'
import toast from "react-hot-toast";

const WaitingTableMaster = () => {
    const [data, setData] = useState(null);
    const [refreshFlag, setRefreshFlag] = useState(0);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const [counter, setCounter] = useState(5);
    const [calling, setCalling] = useState(false);
    const handlePlayingWaitingData = () => {
        if (!calling) {
            setCalling(true);
            callGetApi(Get_Ludo_Template_Waiting_List, {}, (response) => {
                const res = response.responsePacket;
                setData(res);
                setCalling(false);
            }, (error) => {
                console.log(error);
                setCalling(false);
            });
        }
    }

    useEffect(() => {
        handlePlayingWaitingData();
    }, [handleRefresh]);

    useEffect(() => {
        if (window.globalInterval !== null) {
            clearInterval(window.globalInterval);
            window.globalInterval = null;
        }
        window.globalInterval = setInterval(() => {
            if (counter < 0) {
                setCounter(5);
            } else {
                setCounter((pre) => pre - 1);
            }
        }, 1000);
        return () => {
            clearInterval(window.globalInterval);
            window.globalInterval = null;
        }
    }, [])

    useEffect(() => {
        if (counter <= 0) {
            handlePlayingWaitingData();
            setCounter(5);
        }
    }, [counter]);

    const kickPlayer = (table, item) => {
        callGetApi(Get_Kick_Player_From_Waiting.replaceAll("{templateId}", table.templateRecord.recordId).replaceAll("{playerId}", item.playerId), {}, (response) => {
            toast.success(response.message, {
                position: "bottom-right",
            })
        })
    }

    return (
        <div className='mt-2 card p-3'>
            <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-0">Playing/Waiting Players</h5>
                <button type="button" className="btn btn-primary" onClick={() => {
                    setCounter(5);
                    handlePlayingWaitingData();
                }} >
                    Refresh ({counter})
                </button>
            </div>
            <hr className="" />
            <table className="table tamplatelist accordion text-end pb-2 " style={{ background: "#fbfbfb" }}>
                <thead>
                    <tr className='bg-dark text-white'>
                        <th scope="col">Bet</th>
                        <th scope="col">Player Count</th>
                        <th scope="col">Playing</th>
                        <th scope="col">Waiting</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((table, i) => (
                        <React.Fragment key={i}>
                            <tr data-bs-toggle="collapse" data-bs-target={"#r" + i}>
                                <td>
                                    {table.templateRecord.betAmount.toFixed(2)}
                                </td>
                                <td>{table.templateRecord.playerCount}</td>
                                <td className='fw-semibold'>{table.templateRecord.playingPlayers}</td>
                                <td className='fw-semibold'>{table.waitingPlayerList.length}</td>
                            </tr>
                            <tr className="collapse accordion-collapse px-5" id={"r" + i} data-bs-parent=".table" style={{ borderColor: "#fff" }}>
                                <td className='p-0' colSpan={4}>
                                    {table && (table?.waitingPlayerList.map((item, j) => (
                                        <div key={j} className='d-flex justify-content-between align-items-center border bg-white border-top-0 p-2 mx-4'>
                                            <div className='d-flex align-items-center'>
                                                <span className='ms-1 me-1'>{item.playerName}</span>
                                                <span>({item.userName})</span>
                                            </div>
                                            <button
                                                type="button"
                                                className="btn btn-primary py-1"
                                                onClick={() => kickPlayer(table, item)}
                                            >
                                                Kick
                                            </button>
                                        </div>
                                    )))}
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default WaitingTableMaster;