import PaginationDataTable from "Components/common/PaginationDataTable";
import {
    All_Ludo_Game_Table_List_Post,
    Stop_Ludo_Game_Table_Put,
} from "api/ApiConst";
import React, {
    useCallback,
    useContext,
    useMemo,
    useReducer,
    useState,
} from "react";
import { Link } from "react-router-dom";
import { MyContext } from "reducer/userReducers";
import { handleStop } from "utils/UserUtils";

const RunningTableMaster = () => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const { state, dispatch } = useContext(MyContext);
    const [runningTableCount, setRunningTableCount] = useState(0);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag, state.count]);

    const getInMinAndSec = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        return `${minutes} min ${seconds} sec`;
    };

    const columns = useMemo(
        () => [
            // {
            //   name: "Table Type",
            //   selector: (row) =>
            //     row.privatePublicType === "PUBLIC" ? (
            //       <div className="bg-primary px-2 ms-2 rounded text-white">
            //         {row.privatePublicType}
            //       </div>
            //     ) : (
            //       <div className="bg-success px-2 ms-2 rounded text-white">
            //         {row.privatePublicType}
            //       </div>
            //     ),
            //   sortable: true,
            //   grow: 2,
            //   reorder: true,
            // },
            {
                name: "Table Name",
                selector: (row) => (
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            {row.privatePublicType === "PUBLIC" ? (
                                <div className="overflow-visible me-2">
                                    <span className="bg-primary px-2 rounded text-white">
                                        {row.privatePublicType}
                                    </span>
                                </div>
                            ) : (
                                <div className="overflow-visible me-2">
                                    <span className="bg-success px-2 rounded text-white">
                                        {row.privatePublicType}
                                    </span>
                                </div>
                            )}{" "}
                            <div className="text-truncate ms-2">{row.tableTitle}</div>
                        </div>
                        {row.gameSessionId}{" "}
                    </div>
                ),
                sortable: true,
                grow: 2,
                reorder: true,
                width: "196px",
            },
            {
                name: "Bet / Player",
                selector: (row) => (
                    <div>
                        <div>
                            {row.betAmount.toFixed(2)} + {row.feeAmount.toFixed(2)}
                        </div>
                        <div>
                            {row.playingPlayers} / {row.playerCount}
                        </div>
                    </div>
                ),
                sortable: true,
                grow: 2,
                reorder: true,
            },
            // {
            //   name: "Max Players",
            //   selector: (row) => row.maxPlayers,
            //   sortable: true,
            //   grow: 2,
            //   reorder: true,
            // },
            // {
            //   name: "Player",
            //   selector: (row) => `${row.playingPlayers} / ${row.playerCount}`,
            //   sortable: true,
            //   grow: 2,
            //   reorder: true,
            // },
            {
                name: "Table Status",
                selector: (row) => row.tableStatus,
                sortable: true,
                grow: 2,
                reorder: true,
            },
            {
                name: "Limit",
                selector: (row) => (
                    <div>
                        <div>{row.limit}</div>
                        <div>
                            {(row.limit === "TimeLimit" && getInMinAndSec(row.limitValue)) ||
                                (row.limit === "TurnLimit" && row.limitValue + " turn") || (
                                    <React.Fragment></React.Fragment>
                                )}
                        </div>
                    </div>
                ),
                sortable: true,
                grow: 2,
                reorder: true,
            },
            // {
            //   name: "Table Code",
            //   selector: (row) => (row.privatePublicType === "PRIVATE" ? row.tableCode : ""),
            //   sortable: true,
            //   grow: 2,
            //   reorder: true,
            // },
            {
                name: "Actions",
                cell: (row) =>
                    row.deleteTable != true ? (
                        <div className="d-flex gap-2">

                            {/* <Link to={`/watchTable/?cid=SpinItUp&tblId=${row.tableId}`} target="_blank" className="btn btn-lg btn-primary">
                <i className="fa fa-eye" aria-hidden="true"></i>
              </Link> */}
                            <button
                                type="button"
                                className="btn btn-lg btn-primary"
                                onClick={() => handleStopTableAlert(row)}
                            >
                                <i className="fa fa-ban" aria-hidden="true"></i>
                            </button>
                        </div>
                    ) : (
                        ""
                    ),
                // sortable: true,
                right: true,
                reorder: true,
            },
        ],
        [refreshFlag, state.count]
    );

    const handleStopTableAlert = (row) => {
        console.log(row);
        handleStop(
            Stop_Ludo_Game_Table_Put.replaceAll("{recordID}", row.tableId),
            handleRefresh
        );
    };
    return (
        <>
            <div className="card p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Running Table List ( {runningTableCount} )</h5>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleRefresh}
                    >
                        Refresh
                    </button>
                </div>
                <hr className="" />
                <div className="card searchOnlyBlock">
                    <div className="card-body tableView pt-0">
                        <PaginationDataTable
                            paginationUrl={All_Ludo_Game_Table_List_Post}
                            serverSidePagination={false}
                            search={false}
                            pagination={false}
                            columns={columns}
                            countTotalRecords={(count) => {
                                setRunningTableCount(count);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default RunningTableMaster;
