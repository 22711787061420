import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

import { callDeleteApi, callPutApi } from "api/ApiCaller";
import { isEmpty } from "./TextUtils";

//Action Active
export const handleActive = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};

//  Action Inactive
export const handleInactive = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};

//   Action Delete
export const handleDelete = (url, refreshTable) => {
    confirmAlert({
        title: "Delete ?",
        message: "Do you want to delete this record ?",
        icon: "warning",
        buttons: [
            {
                label: "Yes",
                onClick: () => {
                    callDeleteApi(url, {}, (response) => {
                        if (response.errorCode === 0) {
                            toast.success(response.message);
                            refreshTable();
                        } else {
                            toast.error(response.message);
                        }
                    });
                },
            },
            {
                label: "No",
            },
        ],
    });
};

// Action Revive
export const handleRevive = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};


//   Action Publish
export const handlePublish = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};


//   Action Publish
export const handleUnPublish = (url, refreshTable) => {
    confirmAlert({
        title: "Un-Publish ?",
        message: "Do you want to Un-Publish this record ?",
        icon: "warning",
        buttons: [
            {
                label: "Yes",
                onClick: () => {
                    callPutApi(url, {}, {}, (response) => {
                        if (response.errorCode === 0) {
                            toast.success(response.message);
                            refreshTable();
                        } else {
                            toast.error(response.message);
                        }
                    });
                },
            },
            {
                label: "No",
            },
        ],
    });
};


// Start Revive
export const handleStart = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};


//   Action Publish
export const handleStop = (url, refreshTable) => {
    confirmAlert({
        title: "Do you want to Stop game ?",
        message: "",
        icon: "warning",
        buttons: [
            {
                label: "Yes",
                onClick: () => {
                    callPutApi(url, {}, {}, (response) => {
                        if (response.errorCode === 0) {
                            toast.success(response.message);
                            refreshTable();
                        } else {
                            toast.error(response.message);
                        }
                    });
                },
            },
            {
                label: "No",
            },
        ],
    });
};
