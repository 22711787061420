import React from 'react'
import TemplateMaster from './template-master/TemplateMaster';
import WaitingTableMaster from './waiting-table/WaitingTableMaster';
import RunningTableMaster from './running-table/RunningTableMaster';

const Dashboard = () => {
    return (
        <>
            <div className="col-lg-6">
                <TemplateMaster />
            </div>
            <div className="col-lg-6">
                <WaitingTableMaster />
                <RunningTableMaster />
            </div>
        </>
    )
}

export default Dashboard;