
import React, { createContext, useContext, useReducer } from 'react';

const defaultObj = {
    count: 0,
}
// Create a context
const MyContext = createContext();


// Reducer function
const reducer = (state, action) => {
    switch (action.type) {
        case 'REFRESH_RUNNING_TABLE':
            return { count: state.count + 1 };
        case 'DECREMENT':
            return { count: state.count - 1 };
        default:
            return state;
    }
};

// Context provider component with useReducer
const MyContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, { count: 0 });

    return <MyContext.Provider value={{ state, dispatch }}>{children}</MyContext.Provider>;
};


export { MyContext, MyContextProvider };