import React, { useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./App.css";
import { useState } from "react";

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import WatchTable from 'Components/WatchTable';
import Dashboard from "Components/Dashboard";
import ModalBox from 'Components/common/Modal';
import { callPostApiWithoutAuth } from 'api/ApiCaller';
import { User_Login_Post } from 'api/ApiConst';
import { CID, getDataFromStore, LOGINTOKEN, setDataInStore, UserPass } from 'utils/CookiesUitls';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Dashboard />,
    }, {
        path: "/watchTable",
        element: <WatchTable />,
    }
]);

const toastDefaultOptionsStyle = {
    borderRadius: "5px",
    fontSize: "14px",
};

const defaultLoginObj = {
    username: "",
    password: ""
}

function App() {
    const tempOneCID = getDataFromStore(CID);
    const token1 = getDataFromStore(LOGINTOKEN)

    const _token = token1 ? token1 : "";
    const _tempCID = tempOneCID ? tempOneCID : "";

    const [cidValue, setCidValue] = useState(_tempCID)
    const urlParams = new URLSearchParams(window.location.search);
    const cid = urlParams.get(CID);


    // const [activeTab, setActiveTab] = useState("template");
    const [showLoginModal, setShowLoginModal] = useState("flag");
    const [loginToken, setLoginToken] = useState(_token)
    const [loginDetails, setLoginDetails] = useState(defaultLoginObj);

    useEffect(() => {
        let tempCID = "";
        if (cid) {
            tempCID = setDataInStore(CID, cid);
        } else {
            tempCID = getDataFromStore(CID);
            if (!tempCID) {
                setShowLoginModal(false);
                return;
            }
        }
        setCidValue(tempCID);
        const token = getDataFromStore(LOGINTOKEN)
        setLoginToken(token);
        setShowLoginModal(token ? false : true);

        let up = urlParams.get(UserPass);
        if (up) {
            let userPass = up.split(":");
            if (userPass.length == 2) {
                console.log(userPass[0], userPass[1]);
                loginDetails.username = userPass[0];
                loginDetails.password = userPass[1];
                handleLogin();
            }
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginDetails((prev) => ({ ...prev, [name]: value }));
    }

    const handleLogin = () => {
        if (loginDetails.username === "" || loginDetails.username === undefined || loginDetails.username === null) {
            toast.error("Please provide username!!", { id: "loginData" })
            return;
        }
        if (loginDetails.password === "" || loginDetails.password === undefined || loginDetails.password === null) {
            toast.error("Please provide password!!", { id: "loginData" })
            return;
        }
        callPostApiWithoutAuth(User_Login_Post, loginDetails, (response) => {
            if (response.errorCode === 0) {
                const token = response.responsePacket;
                setDataInStore(LOGINTOKEN, token);
                setLoginToken(token)
                setShowLoginModal(false);
            } else {
                toast.error(response.message);
            }
        },
            (e) => {
                toast.error(e.message)
            })
    }

    const handleLoginModel = () => {
        return (
            <ModalBox show={showLoginModal} modalClass={"sliderBlockModal termsAndConditionsModal"}>
                <div className="modal-content bg-purple border-none login-modal">
                    <div className="modal-body pb-0 pt-2">
                        <div className="d-flex justify-content-center">
                            <div className="line-custom"></div>
                        </div>
                        {/* <div className="d-flex justify-content-end">
                                <span type="button" className="btn-close" onClick={() => setShowTermsConditionsModal(false)}>
                                    <span className="cross-yellow" aria-hidden="true">
                                        <img src="assets/img/cross_icon.png" alt="cross" />
                                    </span>
                                </span>
                            </div> */}
                        <div className="p-2">
                            <form className="form-horizontal text-left">
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">
                                        Username
                                    </label>
                                    <input type="text" className="form-control" id="username" name="username" placeholder="Enter username" onChange={(e) => handleChange(e)} />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <div className="input-group auth-pass-inputgroup">
                                        <input
                                            type="password"
                                            className="form-control"
                                            placeholder="Enter password"
                                            aria-label="Password"
                                            name="password"
                                            aria-describedby="password-addon"
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="mt-3 d-grid">
                                    <a className="btn btn-primary waves-effect waves-light" type="submit" onClick={(e) => handleLogin()}>
                                        Log In
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalBox>
        )
    }

    return (
        <>
            <div className="row m-0 ">
                {/* {(urlParams.has("cid") && !!loginToken) ? ( */}
                {(cidValue) ? (
                    (!!loginToken) ?
                        // setShowLoginModal(true)
                        <>
                            <RouterProvider router={router} />

                        </>
                        : handleLoginModel()
                ) : (
                    <div className="my-5 text-center align-items-center">
                        <h4>Invalid Request</h4>
                        <p>Contact to Admin</p>
                    </div>
                )}
            </div>

            <Toaster
                toastOptions={{
                    duration: 2000,
                    style: toastDefaultOptionsStyle,
                }}
            />
        </>
    );
}

export default App;
