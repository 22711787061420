import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom';

const WatchTable = () => {
    const [searchParams] = useSearchParams();
    const { cid, tblId } = Object.fromEntries([...searchParams])
    return (
        <div>{tblId}</div>
    )
}

export default WatchTable;