import { callPostApi } from "api/ApiCaller";
import {
    Activate_Ludo_Game_Template_Put,
    All_Ludo_Game_Template_List_Post,
    DeActivate_Ludo_Game_Template_Put,
    Delete_Ludo_Game_Template_Delete,
    Deleted_Ludo_Game_Template_List_POST,
    Publish_Ludo_Game_Template_Put,
    Revive_Ludo_Game_Template_Put,
    Start_Ludo_Game_Template_Put,
    UnPublish_Ludo_Game_Template_Put,
} from "api/ApiConst";
import React, {
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { Button } from "react-bootstrap";
import TemplateAddEdit from "./TemplateAddEdit";
import {
    handleActive,
    handleDelete,
    handleInactive,
    handlePublish,
    handleRevive,
    handleStart,
    handleUnPublish,
} from "utils/UserUtils";
import { MyContext } from "reducer/userReducers";
import LudoHistory from "Components/common/LudoHistory";

const TemplateMaster = () => {
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordData, setRecordData] = useState([]);
    const [recordId, setRecordId] = useState(null);
    const [viewDeletedList, setViewDeletedList] = useState(false);
    const { state, dispatch } = useContext(MyContext);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        if (!showAddUpdateModal) {
            setRecordId(null);
        }
    }, [showAddUpdateModal]);


    window._showHistoryModel = () => {
        setShowHistoryModal(true);
    }
    useEffect(() => {
        getLudoTemplateList(false);
    }, [refreshFlag, viewDeletedList]);

    const getLudoTemplateList = async (isLoadingMore) => {
        const rUrl = viewDeletedList
            ? Deleted_Ludo_Game_Template_List_POST
            : All_Ludo_Game_Template_List_Post;
        const nextPage = isLoadingMore ? currentPage + 1 : currentPage;
        callPostApi(
            rUrl,
            {
                pageNumber: nextPage,
                pageSize: 1000,
                search: "",
            },
            {},
            (response) => {
                if (response.errorCode === 0) {
                    const res = response.responsePacket.data;
                    if (isLoadingMore) {
                        setRecordData([...recordData, ...res]);
                        if (currentPage === 0) {
                            setCurrentPage(1);
                        } else {
                            setCurrentPage((pre) => pre + 1);
                        }
                    } else {
                        setRecordData(res);
                    }
                    setTotalRecords(response.responsePacket.totalItems);
                }
            }
        );
    };

    const handleLoadMoreRecordsList = () => {
        getLudoTemplateList(true);
    };

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
        dispatch({ type: "REFRESH_RUNNING_TABLE" });
    }, [refreshFlag]);

    // template Action Buttons
    const getActionButtons = (row) => {
        return (
            <div className="dropdown">
                <button
                    className="h3 mb-0 btn btn-flat btn-addon btn-xs dropdown-toggle text-white"
                    title="More"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{ padding: "5px 10px 8px 10px" }}
                >
                    <i className="bx bx-dots-vertical-rounded"></i>
                </button>
                <ul className="dropdown-menu dropdown-menu-right">
                    <>
                        {row?.deleted ? (
                            <li onClick={() => handleReviveData(row)}>
                                <i className="bx bx-log-out-circle"></i> Revive
                            </li>
                        ) : (
                            <>
                                <li onClick={() => handleEdit(row)}>
                                    <i className="bx bx-pencil"></i> Edit
                                </li>
                                <li onClick={() => handleDeleteData(row)}>
                                    <i className="bx bx-trash"></i> Delete
                                </li>
                                <li onClick={() => handleActiveInactive(row)}>
                                    {row?.active ? (
                                        <>
                                            <i className="bx bx-x-circle"></i> Inactive
                                        </>
                                    ) : (
                                        <>
                                            <i className="bx bx-check-circle"></i> Active
                                        </>
                                    )}
                                </li>
                                <li onClick={() => handleStartTemplate(row)}>
                                    <i className="bx bx-play"></i> Start Game
                                </li>
                            </>
                        )}
                    </>
                </ul>
            </div>
        );
    };

    //  Edit template
    const handleEdit = (row) => {
        setRecordId(row.recordId);
        setShowAddUpdateModal(true);
    };

    // delete template
    const handleDeleteData = (row) => {
        handleDelete(
            Delete_Ludo_Game_Template_Delete.replaceAll("{recordID}", row.recordId),
            handleRefresh
        );
    };

    // Revive template
    const handleReviveData = (row) => {
        handleRevive(
            Revive_Ludo_Game_Template_Put.replaceAll("{recordID}", row.recordId),
            handleRefresh
        );
    };

    // Active-DeActive template record
    const handleActiveInactive = (row) => {
        if (row.active) {
            handleInactive(
                DeActivate_Ludo_Game_Template_Put.replaceAll(
                    "{recordID}",
                    row.recordId
                ),
                handleRefresh
            );
        } else {
            handleActive(
                Activate_Ludo_Game_Template_Put.replaceAll("{recordID}", row.recordId),
                handleRefresh
            );
        }
    };

    // Start template
    const handleStartTemplate = (row) => {
        handleStart(
            Start_Ludo_Game_Template_Put.replaceAll("{recordID}", row.recordId),
            handleRefresh
        );
    };

    // Publish-unPublish template
    const handlePublishUnPublish = (row) => {
        if (row.published) {
            handleUnPublish(
                UnPublish_Ludo_Game_Template_Put.replaceAll("{recordID}", row.recordId),
                handleRefresh
            );
        } else {
            handlePublish(
                Publish_Ludo_Game_Template_Put.replaceAll("{recordID}", row.recordId),
                handleRefresh
            );
        }
    };

    // toggle delete-list view
    const handleToggleDeletedView = () => {
        setTotalRecords(0);
        setCurrentPage(0);
        setRecordData([]);
        setViewDeletedList(!viewDeletedList);
    };

    return (
        <>
            <div className="mt-2 card p-3">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Ludo Game Templete</h5>
                    <div className="deletedBtn ml-auto">
                        {/* <Button
                            className="me-2"
                            onClick={() => setShowHistoryModal(true)}
                        >
                            History
                        </Button> */}
                        <Button
                            className="me-2"
                            onClick={() => setShowAddUpdateModal(true)}
                        >
                            {" "}
                            + Add{" "}
                        </Button>
                        <label className="btn btn-primary">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                onChange={handleToggleDeletedView}
                            />
                            Deleted List
                        </label>
                    </div>
                </div>
                <hr className="" />
                {recordData.length !== 0 &&
                    recordData.map((data, i) => (
                        <div className="card border bg-light " key={i}>
                            <div className="d-flex justify-content-between align-items-center bg-dark text-white py-1 rounded-top">
                                <div className="d-flex align-items-center">
                                    <span
                                        className={`${data.active ? "led-green" : "led-red"} me-2`}
                                    ></span>
                                    <span>{data.templateTitle}</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <span className=" bg-success px-2 rounded">
                                        {data.published && "Published"}
                                    </span>
                                    <div className="h3 mb-0 text-white cursor-pointer">
                                        {getActionButtons(data)}
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2">
                                <table className="table mb-0 tamplatelist">
                                    <tbody>
                                        <tr>
                                            <th scope="row">Bet</th>
                                            <td>
                                                <span title="Bet Amount">
                                                    {data.betAmount.toFixed(2)} ₹
                                                </span>
                                                {data.feeAmount && (
                                                    <span title="Fee Amount">
                                                        {" "}
                                                        {" + "} {data.feeAmount.toFixed(2)} ₹
                                                    </span>
                                                )}
                                            </td>
                                            <th scope="row">Player</th>
                                            <td>
                                                <span>
                                                    {data.playerCount}
                                                    {" (Min - "} {data.minimumPlayerToStart} {" )"}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Winner</th>
                                            <td>
                                                {" "}
                                                <span>
                                                    {data.totalNumberOfWinners} {"("}{" "}
                                                    {data.everyWinnerWinningPercentage.length !== 0 &&
                                                        data.everyWinnerWinningPercentage
                                                            .map((item) => item + "% | ")
                                                            .join("")
                                                            .slice(0, -3)}{" "}
                                                    {")"}
                                                </span>
                                            </td>
                                            <th scope="row">Token</th>
                                            <td>
                                                <div>
                                                    <span>{data.tokenCount}</span>
                                                    <span className="bg-info px-2 ms-2 rounded text-white">
                                                        {data.tokenStartPosition}
                                                    </span>
                                                </div>
                                                {data.startBySixOnly && (
                                                    <span className="text-danger">
                                                        * Start By Six Only
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="row" colSpan={4}>
                                                <span className="d-flex">
                                                    <span className="d-flex">
                                                        <i
                                                            className={`bx ${data.capturePointsOnTokenCapture
                                                                ? "bx-check text-success"
                                                                : "bx-x text-danger"
                                                                } h4 mb-0 bg-dark rounded me-2`}
                                                        />{" "}
                                                        Capture Point &nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <span className="d-flex">
                                                        <i
                                                            className={`bx ${data.skipDiceTurnIfNotRolled
                                                                ? "bx-check text-success"
                                                                : "bx-x text-danger"
                                                                } h4 mb-0 bg-dark rounded me-2`}
                                                        />{" "}
                                                        Skip Dice &nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    <span className="d-flex">
                                                        <i
                                                            className={`bx ${data.skipPlayTurnIfNotPlayed
                                                                ? "bx-check text-success"
                                                                : "bx-x text-danger"
                                                                } h4 mb-0 bg-dark rounded me-2`}
                                                        />{" "}
                                                        Skip Turn
                                                    </span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td scope="row" colSpan={3}>
                                                <div className="d-flex">
                                                    <span className="bg-secondary text-white px-2 rounded">
                                                        {data.boardType}
                                                    </span>{" "}
                                                    &nbsp;
                                                    <div className="d-flex align-items-center bg-secondary text-white px-2 rounded ">
                                                        <span>
                                                            <b>{data.limit.replace(/([A-Z])/g, " $1")} :- </b>
                                                        </span>
                                                        <span>
                                                            {data.limitValue}{" "}
                                                            {data.limit === "TurnLimit" ? "turn" : "Sec"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            {!data.deleted && (
                                                <td scope="row">
                                                    <div className="text-end">
                                                        <Button
                                                            className={`py-1 px-2 ${data.published ? "bg-danger" : "btn btn-primary"
                                                                } border-0`}
                                                            variant="primary"
                                                            onClick={() => handlePublishUnPublish(data)}
                                                        >
                                                            {data.published ? "Un-Publish" : "Publish"}
                                                        </Button>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                {recordData.length < totalRecords && (
                    <Button onClick={handleLoadMoreRecordsList}>Load More</Button>
                )}
            </div>
            {showHistoryModal && (
                <LudoHistory
                    show={showHistoryModal}
                    hideModal={() => setShowHistoryModal(false)}
                    recordId={recordId}
                    handleRefresh={handleRefresh}
                />
            )}
            {showAddUpdateModal && (
                <TemplateAddEdit
                    show={showAddUpdateModal}
                    hideModal={() => setShowAddUpdateModal(false)}
                    recordId={recordId}
                    handleRefresh={handleRefresh}
                />
            )}
        </>
    );
};

export default TemplateMaster;
